angular.module('cpformplastApp')
  .controller('MachinesProductionModal', function ($scope, Util, appConfig, $http, SubjobProductionReport, Machine, Notification, $modalInstance, machines, DataManager) {

    $scope.objectFieldtoLocaleString = function(object, fieldName) {
      if (object == null) {
        return '';
      } else if (typeof object[fieldName] === 'number') {
        return Number(object[fieldName]).toLocaleString();
      } else {
        return object[fieldName];
      }
    }

    $scope.objectFieldsToLocaleString = function(object, fields) {
      const nextObj = {};
      for (const fieldName of fields) {
        nextObj[fieldName] = $scope.objectFieldtoLocaleString(object, fieldName);
      }
      return nextObj;
    }
    
    $scope.machines = machines.map(machine => ({
      ...machine,
      activeSubjobSchedule: {
        ...machine.activeSubjobSchedule,
        // Make those number pretty-print (by adding commas between thousands)
        ...$scope.objectFieldsToLocaleString(
          machine.activeSubjobSchedule, 
          ['lastBoxIndex', 'quantity', 'producedQuantityToday', 'producedQuantity']
        ),
      }
    }));

    $scope.save = function() {
      for (var i=0 ; i<$scope.machines.length ; i++) {
        if ($scope.machines[i].activeSubjobSchedule && $scope.machines[i].activeSubjobSchedule.newBoxIndex) {
          $scope.createProductionReport($scope.machines[i].activeSubjobSchedule)
        }
      }

      $scope.detectSaveCompletion()
    }

    $scope.print = function() {
      DataManager.downloadProductionReportPdf();
    }
    
    $scope.createProductionReport = function(subjobSchedule) {
      const url = appConfig.api.url + "/api/jobs/" + subjobSchedule.subjob.job.id + "/subjobs/" + subjobSchedule.subjob.id + "/schedules/" + subjobSchedule.id
      return $http.post(url, { boxIndex: Number.parseInt(subjobSchedule.newBoxIndex) })
        .then(res => {
          subjobSchedule.isSaved = true
          $scope.detectSaveCompletion()
        })
        .catch(error => { Notification.error('Un problème est survenu'); })
    }

    $scope.detectSaveCompletion = function() {
      var completed = true

      for (var i=0 ; i<machines.length ; i++) {
        if ($scope.machines[i].activeSubjobSchedule && $scope.machines[i].activeSubjobSchedule.newBoxIndex && !$scope.machines[i].activeSubjobSchedule.isSaved) {
          completed = false
        }
      }

      if (completed) { $scope.fetchMachines(); }
    }

    $scope.fetchMachines = function() {
      $http.get(appConfig.api.url + '/api/jobs/schedules')
      .then(req => req.data.map(o => new Machine(o)))
      .then(machines => {
        $scope.machines = machines.map(machine => ({
          ...machine,
          activeSubjobSchedule: {
            ...machine.activeSubjobSchedule,
            // Make those number pretty-print (by adding commas between thousands)
            ...$scope.objectFieldsToLocaleString(
              machine.activeSubjobSchedule, 
              ['lastBoxIndex', 'quantity', 'producedQuantityToday', 'producedQuantity']
            ),
          }
        }));
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des cédules');
      });
    };

  });
